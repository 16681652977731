import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['fullWidth'].includes(prop),
})<{
    fullWidth?: boolean;
}>(
    ({ theme, fullWidth }) => css`
        font-size: 1.8rem;
        font-weight: 500;
        text-align: center;
        padding: ${theme.gap.xlarge} ${theme.gap.medium};
        margin-bottom: ${theme.gap.xlarge};
        border-radius: ${theme.radius.large};
        border: 0.1rem dashed ${theme.palette.common.border};
        width: ${fullWidth ? '100%' : 'unset'};
    `,
);

export const IconWrapper = styled.div(
    ({ theme }) => css`
        margin-bottom: ${theme.gap.medium};

        i {
            width: 12rem;
            height: 12rem;
            color: ${theme.palette.color.gray.light};
            filter: drop-shadow(2px 2px 0 rgba(255, 255, 255, 1));
            filter: drop-shadow(-1px -1px 0 rgba(0, 0, 0, 0.15));
        }
    `,
);

export const Content = styled.div(
    ({ theme }) => css`
        font-weight: 700;
        color: ${theme.palette.color.gray.main};
    `,
);

export const ButtonWrapper = styled.div(
    ({ theme }) => css`
        margin-top: ${theme.gap.vlarge};
    `,
);
