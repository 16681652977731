import React from 'react';
import { Button } from '../form/button/button';
import { Wrapper, IconWrapper, Content, ButtonWrapper } from './no-data.styled';
import { IconEmoticonSad } from '../icons/emoticon-sad';
import { IconPlus } from '../icons/plus';

interface NoDataPropsInterface {
    children: Array<JSX.Element> | JSX.Element | string;
    actionOnClick?: () => void;
    actionLabel?: string;
    fullWidth?: boolean;
}

export const NoData: React.FC<NoDataPropsInterface> = ({
    children,
    actionOnClick,
    actionLabel,
    fullWidth,
}: NoDataPropsInterface) => {
    return (
        <Wrapper fullWidth={fullWidth}>
            <IconWrapper>
                <IconEmoticonSad />
            </IconWrapper>
            <Content>{children}</Content>
            {actionOnClick && (
                <ButtonWrapper>
                    <Button
                        variant="primary"
                        icon={<IconPlus />}
                        size={{ xs: 'medium', md: 'vlarge' }}
                        onClick={actionOnClick}
                    >
                        {actionLabel}
                    </Button>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};
